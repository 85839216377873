export const ENVIRONMENT_CONFIG = {
    dev: {
        apiUrl: 'https://api-dev.albicchiere.com',
        cognitoUserPool: 'eu-west-1_BnxRNXEeL',
        cognitoClientId: 'pt38t1qd8l7564813bh8pm0v6',
        externalElabelUrl: 'https://elabel-dev.albicchiere.com',
        cognitoDomain: 'https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_BnxRNXEeL/.well-known/jwks.json',
        redirectUrl: 'http://dashboard-dev.albicchiere.com/callback',

    },
    staging: {
        apiUrl: 'https://api-staging.albicchiere.com',
        cognitoUserPool: 'us-east-1_1zaTQGSFY',
        cognitoClientId: '2etmbftl1nd6d96q7723uc5s1i',
        externalElabelUrl: 'https://elabel-staging.albicchiere.com',

        cognitoDomain: 'https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_BnxRNXEeL/.well-known/jwks.json',
        redirectUrl: 'http://localhost:4200/callback',
    },
    prod: {
        apiUrl: 'https://api.albicchiere.com',
        cognitoUserPool: 'eu-west-1_Mq2rXIDUU',
        cognitoClientId: '4saefnns4317ni5ivbar7li32',
        externalElabelUrl: 'https://elabel.albicchiere.com',

        cognitoDomain: 'https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_BnxRNXEeL/.well-known/jwks.json',
        redirectUrl: 'http://localhost:4200/callback',
    }
}