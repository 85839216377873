<ng-container *ngIf="{
    isLoading: isLoading$ | async
} as vm">
    <div class="bright-container">
        <img src="assets/login_img.png" class="login-image">
    </div>
    <div class="dark-container">
        <router-outlet></router-outlet>
    </div>
    <div *ngIf="vm.isLoading" class="loading-overlay">
        <div class="loader-container">
            <albi-loader></albi-loader>
        </div>
    </div>
</ng-container>